<template>
  <div>
    <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
      <b-card v-if="bookDetails" class="mb-2">
        <div class="card-header page">
          <h1 class="card-title2">
            {{ bookDetails.name }}
          </h1>
          <p class="card-text">
            ISBN: {{ bookDetails.isbn }}
          </p>
          <span class="text-muted">Autor(es): {{ bookDetails.author }}</span>
        </div>

        <div class="activity" v-for="(tr, indextr) in bookDetails.lessons">
          <a :href="'/livro/aula-detalhe/' + tr.id"><h4 class="card-title2">{{ tr.name }}</h4></a>
          <hr />
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import noData from "@/components/systemWarning/NoData.vue";

export default {
  components: {
    BCard,
    noData,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      bookDetails: null,
    };
  },
  computed: {},
  methods: {
    showBook() {
      this.isLoading = true;
      this.$store
        .dispatch("books/fetchDetails", {
          onlyLessons: true,
          book_id: this.$route.params.book_id,
          paginate: false,
        })
        .then((response) => {
          this.bookDetails = response;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.showBook();
  },
};
</script>
<style>
.activity {
  padding: 0.5rem 1.5rem
}

.gray-line {
  background-color: #090909;
  height: 50px;
  line-height: 50px;
  color: white;
  width: 200px;
  margin-left: 100px;
  padding-left: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  top: 76px;
  left: 0;
  text-transform: uppercase;
}

.icon-container {
  background-color: #090909;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.card-title2 {
  font-weight: 500;
}

strong {
  font-size: 1.286rem;
}
</style>
